<template>
  <footer class="layout-footer">
    <div class="container">
      <div class="footer-flex">
        <div class="inco-wrap">
          <h2>
            ㈜인실리코젠
          </h2>
          <p>Read Data, Lead Big<span class="line"></span>바이오 빅데이터의 리더</p>

          <div class="company">
            <span>사업자 등록번호</span>
            <span>120-86-84671</span>
            <span>대표자</span>
            <span>최남우</span>
          </div>
          <div class="address">
            (16514) 경기도 용인시 기흥구 흥덕1로 13 흥덕IT밸리 타워 A동 2901-2904, 2906호
          </div>
        </div>

        <div class="service">
          <div class="d-flex">
            <div class="wrap">
              <b>서비스 안내</b>
              <router-link to="#">아이푸드 소개</router-link>
              <router-link to="#">블로그</router-link>
            </div>
            <div class="wrap">
              <b>고객지원</b>
              <p>TEL : 031-895-5123 (10:00~18:00)</p>
              <p>EMAIL : support@d-if.kr</p>
              <p>FAX : 0505 870 5123</p>
            </div>
          </div>
        </div>

        <div class="term-wrap">
          <!--<router-link to="#">서비스 이용약관</router-link>
          <router-link to="#">개인정보처리방침</router-link>-->
        </div>

        <div class="link-wrap">
          <ul class="link-btn">
            <li><a href="https://d-if.kr" target="_blank"><i class="inco inco-dif"><span class="sr-only">d-if icon</span></i></a></li>
            <li><a href="https://www.instagram.com/datafood_d.if" target="_blank"><i class="inco inco-instagram"><span class="sr-only">instagram icon</span></i></a></li>
            <li><a href="https://www.facebook.com/datafooddif" target="_blank"><i class="inco inco-facebook"><span class="sr-only">facebook icon</span></i></a></li>
            <li><a href="https://brunch.co.kr/@dif" target="_blank"><i class="inco inco-branch"><span class="branch"></span></i></a></li>
          </ul>
        </div>

        <p class="copyright">© 2005~{{ date }} INSILICOGEN, INC. ALL RIGHTS RESERVED.</p>

      </div>

    </div>
  </footer>
</template>

<script>
export default {
  name: 'AppFooter',
  data() {
    return {
      date: new Date().getFullYear()
    }
  },
}
</script>