import $ from 'jquery';

window.addEventListener('load', () => {
});

/**
 * 공통함수
 */
const Utils = (function () {
  
  init();
  /**
   * Initialize (Only Once)
   */
  function init() {
    setHeaderEvent();
    //setCheckAll();
    // setTooltip();
  }
  /**
   * Set Header Tab Event For Accessibility
   */
  function setHeaderEvent() {
    // #region mobile menu event
    $(document).on('click', '.navbar-nav.mobile li a', () => {
      $('.layout-header').append('<div class="header-backdrop modal-backdrop fade"></div>');
      $('.navbar.mobile').addClass('active');
      $('.navbar.mobile').addClass('visible');
      setTimeout(() => {
        $('.header-backdrop').addClass('show');
      }, 100);
    });
    $(document).on('click', '.header-backdrop, .btn-navbar-close', () => {
      $('.header-backdrop').removeClass('show');
      $('.navbar.mobile').removeClass('active');
      setTimeout(() => {
        $('.header-backdrop').remove();
        $('.navbar.mobile').removeClass('visible');
      }, 300);
    });
    // #endregion mobile menu event
    // #region tab event
    $(document).on('focusin', '.layout-header .navbar-nav .nav-item', function () {
      removeMenuActive();
      $(this).addClass('active');
    });
    $(document).on('click', '.layout-header .navbar-nav .nav-item', function () {
      // $navItem.removeClass('active-only');
      // $(this).addClass('active-only');
      $(this).removeClass('active');
    });
    $(document).on('focusin', '.navbar-brand, .layout-header .navbar-nav li > a', function () {
      removeMenuActive();
    });
    $(document).on('mousedown', function () {
      removeMenuActive();
    });
    $(document).on('focusin', '.layout-main, .layout-footer', function () {
      removeMenuActive();
    });
    function removeMenuActive() {
      $('.layout-header .navbar-nav .nav-item').removeClass('active');
    }
    // #endregion tab event
  }
  //function setCheckAll() {
  //  $(document).on('click', '.btn-check-all', function() {
  //    $('.ta-table-wrap .ta-checkbox-wrap input').prop('checked', true);
  //    $('.btn-check-all').addClass('btn-checkrm-all').removeClass('btn-check-all').text('전체 해제');
  //  });
  //  $(document).on('click', '.btn-checkrm-all', function() {
  //    $('.ta-table-wrap .ta-checkbox-wrap input').prop('checked', false);
  //    $('.btn-checkrm-all').addClass('btn-check-all').removeClass('btn-checkrm-all').text('전체 선택');
  //  });
  //  $(document).on('change', '.ta-table-wrap .ta-checkbox-wrap input.each', (e) => {
  //    let $tableWrap = $(e.target).parents('.ta-table-wrap');
  //    let $eachLength = $tableWrap.find('input.each').length;
  //    let $eachCheckedLength = $tableWrap.find('input.each:checked').length;
  //    if ($eachLength == $eachCheckedLength) {
  //      $('.btn-check-all').addClass('btn-checkrm-all').removeClass('btn-check-all').text('전체 해제');
  //    } else {
  //      $('.btn-checkrm-all').addClass('btn-check-all').removeClass('btn-checkrm-all').text('전체 선택');
  //    }
  //  });
  //}

  return {
    /**
     * Set Datepicker
     */
    setDatepicker() {
      // let $datepicker = $('.datepicker');
      // $datepicker.datepicker({ dateFormat: 'yy년 m월 d일' });
      // $datepicker.datepicker().datepicker('setDate', 'today');

      // daterangepicker
      let $daterangepicker = $('.custom-daterangepicker');
      $daterangepicker.each(function () {
        const d = new Date();

        // 오늘날의 년, 월, 일 데이터
        const day = d.getDate();
        const month = d.getMonth();
        const weekAgo = new Date(new Date().setDate(day - 7));
        const monthAgo = new Date(new Date().setMonth(month - 1));

        $(this).daterangepicker({
          autoUpdateInput: false,
          // startDate: new Date(monthAgo),
          endDate: new Date(),
          locale: {
            format: 'YYYY-MM-DD',
            separator: ' ~ ',
            applyLabel: '확인',
            cancelLabel: '취소',
          },
          ranges: {
            오늘: [new Date(), new Date()],
            '7일 전': [weekAgo, new Date()],
            '30일 전': [monthAgo, new Date()],
          },
        });
        $(this).on('apply.daterangepicker', function (ev, picker) {
          $(this).val(picker.startDate.format('YYYY-MM-DD') + ' ~ ' + picker.endDate.format('YYYY-MM-DD'));
        });
        $(this).on('focus', function () {
          $('[data-range-key="Custom Range"]').text('맞춤');
        });
        $(this).data('daterangepicker').setStartDate(monthAgo);
        $(this).val(Utils.dateFormatter(monthAgo).dash + ' ~ ' + Utils.dateFormatter(new Date()).dash);
      });
    },
    /**
     * Set Tooltip
     */
    setTooltip() {
      $(document).on('mouseenter', '[data-toggle="tooltip"]', function () {
        $(this).tooltip({
          content: function () {
            return $(this).prop('title');
          },
        });
        $(this).tooltip('open');
      });
    },
    /**
     * Set Table Checkbox
     */
    setTableCheckbox() {
      $(document).on('change', '.ta-table-wrap input.all', (e) => {
        let $tableWrap = $(e.target).parents('.ta-table-wrap');
        let $eachCheckboxList = $tableWrap.find('input.each');
        $eachCheckboxList.prop('checked', $(e.target).prop('checked'));
      });
      $(document).on('change', '.ta-table-wrap input.each', (e) => {
        let $tableWrap = $(e.target).parents('.ta-table-wrap');
        let $allCheckbox = $tableWrap.find('input.all');
        let $eachLength = $tableWrap.find('input.each').length;
        let $eachCheckedLength = $tableWrap.find('input.each:checked').length;
        if ($eachLength == $eachCheckedLength) {
          $allCheckbox.prop('checked', true);
        } else {
          $allCheckbox.prop('checked', false);
        }
      });
    },
    /**
     * Set Validation
     */
    setValidation() {
      let elements = document.querySelectorAll('input, textarea');

      for (let i = 0; i < elements.length; i++) {
        elements[i].oninvalid = function () {
          // e.target.setCustomValidity(' ');
          elements[i].classList.remove('is-valid');
          elements[i].classList.add('is-invalid');
        };
        elements[i].oninput = function (e) {
          // e.target.setCustomValidity('');
          if (e.target.required) {
            // 비밀번호 pattern 재설정(사용자 수정을 제한하기 위해)
            if(e.target.type == 'password' && e.target.id != 'idOldPassword') {
              e.target.pattern = '^.*(?=^.{8,}$)(?=.*\\d)(?=.*[a-zA-Z])(?=.*[`~!@#$%^&\\*\\-_+=<>?{}\\[\\]\\/\\|\\\\]).*$';
            }
            if (e.target.validity.valid) {
              elements[i].classList.remove('is-invalid');
              elements[i].classList.add('is-valid');
            } else {
              elements[i].classList.remove('is-valid');
              elements[i].classList.add('is-invalid');
            }
          }
        };
      }
    },
    /**
     * Start Loading
     */
    startLoading() {
      let wrapElmnt = document.querySelector('.spinner-wrap');
      let contentWrap = document.querySelector('#content');

      if (wrapElmnt) {
        wrapElmnt.dataset.task = Number(wrapElmnt.dataset.task) + 1;
      } else {
        wrapElmnt = document.createElement('div');
        wrapElmnt.classList.add('spinner-wrap');
        wrapElmnt.dataset.task = 1;
        let loadingElmnt = document.createElement('div');
        loadingElmnt.classList.add('spinner-border');
        let span = document.createElement('span');
        span.classList.add('sr-only');
        span.innerHTML = 'Loading...';
        loadingElmnt.appendChild(span);
        wrapElmnt.appendChild(loadingElmnt)

        contentWrap.appendChild(wrapElmnt);
      }
    },
    /**
     * End Loading
     */
    endLoading() {
      setTimeout(function () {
        let wrapElmnt = document.querySelector('.spinner-wrap');

        if(wrapElmnt) {
          let taskCount = wrapElmnt.dataset.task;

          if (taskCount > 1) {
            wrapElmnt.dataset.task = Number(wrapElmnt.dataset.task) - 1;
          } else {
            wrapElmnt.style.opacity = 0;
            setTimeout(function () {
              wrapElmnt.remove();
            }, 300);
          }
        }
      }, 300);
    },
    /**
     * Number to KRW format
     * ex) 1000000 -> 1,000,000
     * @param {Number} value
     * @returns {String}
     */
    numberFormatter(value) {
      if (value != '' && value != null && typeof value == 'number') {
        value = String(value).replace(/[^\d]+/g, '').replace(/(^0+)/, '').replace(/(\d)(?=(?:\d{3})+(?!\d))/g, '$1,');
      } else {
        value = 0;
      }
      return value === '' ? 'NaN' : value;
    },
    /**
     * Get input[type=file] detail
     * @param {Element} elmnt
     * @returns {Object}
     */
    getFileDetail(elmnt) {
      //파일 경로.
      let filePath = elmnt.value;
      //전체경로를 \ 나눔.
      let filePathSplit = filePath.split('\\');
      // 파일 전체명
      let originalFileName = filePathSplit[filePathSplit.length - 1];
      //파일확장자 앞 .의 index
      let lastDot = originalFileName.lastIndexOf('.');
      //파일명 : .으로 나눈 앞부분
      let fileName = originalFileName.substring(0, lastDot);
      //파일 확장자 : .으로 나눈 뒷부분
      let fileExt = originalFileName.substring(lastDot + 1, originalFileName.length).toLowerCase();
      //파일 크기
      let fileSize = elmnt.files[0].size;

      let object = {
        originalName: originalFileName,
        name: fileName,
        ext: fileExt,
        size: fileSize,
      };

      return object;
    },
    /**
     * Byte to size
     * return ex) 5 GB
     * @param {Number} byte
     * @returns {String}
     */
    byteFormatter(byte) {
      let sizes = ['Byte', 'KB', 'MB', 'GB', 'TB'];
      if (byte == 0) return '0 Byte';
      let i = parseInt(Math.floor(Math.log(byte) / Math.log(1024)));
      return Math.round(byte / Math.pow(1024, i), 2) + ' ' + sizes[i];
    },
    /**
     * Set date format
     * @param {String} date
     * @returns {Object}
     */
    dateFormatter(date) {
      if ((date == '' || date == null) && typeof date == 'string') {
        return '';
      }
      const addZero = (num, digits) => {
        let zero = '';
        num = num.toString();

        if (num.length < digits) {
          for (let i = 0; i < digits - num.length; i++) {
            zero += '0';
          }
        }
        return zero + num;
      };
      let newDate = new Date(date);

      let yyyy = newDate.getFullYear();
      let mm = addZero(newDate.getMonth() + 1, 2);
      let m = newDate.getMonth() + 1;
      let dd = addZero(newDate.getDate(), 2);
      let d = newDate.getDate();

      let object = {
        slash: yyyy + '/' + mm + '/' + dd,
        dot: yyyy + '.' + mm + '.' + dd,
        dash: yyyy + '-' + mm + '-' + dd,
        word: yyyy + '년 ' + m + '월 ' + d + '일',
        time: yyyy + '-' + mm + '-' + dd + ' ' + newDate.getHours() + ':' + newDate.getMinutes() + ':' + newDate.getSeconds(),
      };

      return object;
    },
    /**
     * Get elapsed time
     * @param {String} date
     * @returns {Object}
     */
    getElapsedTime(date) {
      const start = new Date(date);
      const end = new Date(); // 현재 날짜
      
      const diff = (end - start) / 1000; // 경과 시간
    
      const times = [
        { name: '년', milliSeconds: 60 * 60 * 24 * 365 },
        { name: '개월', milliSeconds: 60 * 60 * 24 * 30 },
        { name: '일', milliSeconds: 60 * 60 * 24 },
        { name: '시간', milliSeconds: 60 * 60 },
        { name: '분', milliSeconds: 60 },
      ];
      
      // 년 단위부터 알맞는 단위 찾기
      for (const value of times) {
        const betweenTime = Math.floor(diff / value.milliSeconds);
        
        // 큰 단위는 0보다 작은 소수 단위 나옴
        if (betweenTime > 0) {
          return `${betweenTime}${value.name} 전`;
        }
      }
      
      // 모든 단위가 맞지 않을 시
      return "방금 전";
    },
    getPercent(totalNum, num) {
      return Math.round((num / totalNum) * 100 * 100) / 100;
    },
    getShortNumber(num, digits) {
      var units = ['k', 'M', 'G', 'T', 'P', 'E', 'Z', 'Y'];
      var decimal;

      for(var i=units.length-1; i>=0; i--) {
          decimal = Math.pow(1000, i+1);

          if(num <= -decimal || num >= decimal) {
              return +(num / decimal).toFixed(digits) + units[i];
          }
      }

      return num;
    },
    indexScroll() {
      // header
      let scrollPosition = window.scrollY;
      if (scrollPosition >= 80 ) {
        $('.layout-header').addClass('scrolled');
      } else {
        $('.layout-header').removeClass('scrolled');
      }

      // suggest
      let suggest = document.querySelector(".suggest");
      const indexW = document.querySelector('.index').offsetWidth;
      const leftEl = suggest.querySelector('.from-left');
      const rightEl = suggest.querySelector('.from-right');
      let moveMax = (indexW - (leftEl.offsetWidth + rightEl.offsetWidth + 16)) / 2;

      let suggestRect = suggest.getBoundingClientRect();
      let suggestShown = suggest.querySelector('.bg').offsetHeight;
      if (suggestRect.top <= 0 && suggestRect.bottom >= suggestShown) {
        let relativeScroll = (0-suggestRect.top);
        leftEl.style.transform = `translateX(${Math.min(relativeScroll, moveMax)}px)`;
        rightEl.style.transform = `translateX(-${Math.min(relativeScroll, moveMax)}px)`;
        $('section.suggest').addClass('attached');
      } else {
        $('section.suggest').removeClass('attached');
      }

      let composition = document.querySelector(".composition");
      let compositionRect = composition.getBoundingClientRect();
      let compositionShown = document.querySelector('.composition-container').offsetHeight;
      let imgWrap = composition.querySelector('.img-container').getBoundingClientRect().height;
      let margin = parseFloat($('.composition-tab').css('margin-top')) + parseFloat($('.composition-tab').css('margin-bottom'));

      let breakpoint = getComputedStyle(document.querySelector('body'), ':before').getPropertyValue('content');
      breakpoint = breakpoint.replaceAll('"', '');

      console.log('breakpoint', breakpoint)

      let gap = breakpoint == 'md' ? 100 : breakpoint == 'sm' ? 80 : 20;
      //composition
      if (compositionRect.top <= 0 && compositionRect.bottom >= compositionShown) {

        let relativeScroll = (0-compositionRect.top) + (gap/compositionRect.height);
        //let findIndex = compositionRect.height / 4;
        //let findIndex = compositionRect.height / 4;
        let findIndex = (imgWrap / 4) + margin - gap;
        composition.querySelector('.img-container').style.transform = `translateY(${relativeScroll}px)`;


        if(relativeScroll < findIndex) {
          $('.composition-tab .tab-list').attr('data-active', 1);
        } else if(relativeScroll < (findIndex * 2)) {
          $('.composition-tab .tab-list').attr('data-active', 2);
        } else if(relativeScroll < (findIndex * 3)) {
          $('.composition-tab .tab-list').attr('data-active', 3);
        } else {
          $('.composition-tab .tab-list').attr('data-active', 4);
        }
        $('section.composition').addClass('attached');
      } else {
        $('section.composition').removeClass('attached');
      }
    },
    indexNumRoll() {
      // counter number
      const stats = document.querySelectorAll(".counter");
      stats.forEach(stat => {
        // pattern used to seperate input number from html into an array of numbers and non numbers. EX $65.3M -> ["$65.3M", "$", "65", ".", "3", "M"]
        const patt = /(\D+)?(\d+)(\D+)?(\d+)?(\D+)?/;
        const time = 500;
        let result = [...patt.exec(stat.textContent)];
        let fresh = true;
        let ticks;

        // Remove first full match from result array (we dont need the full match, just the individual match groups).
        result.shift();
        // Remove undefined values from result array where they didnt have a match in one of the optional regex groups
        result = result.filter(res => res != null);

        while (stat.firstChild) {
          stat.removeChild(stat.firstChild);
        }

        for (let res of result) {
          if (isNaN(res)) {
            stat.insertAdjacentHTML("beforeend", `<span>${res}</span>`);
          } else {
            for (let i = 0; i < res.length; i++) {
              stat.insertAdjacentHTML(
                "beforeend",
                `<span data-value="${res[i]}">
                  <span>0</span>
                  <span>1</span>
                  <span>2</span>
                  <span>3</span>
                  <span>4</span>
                  <span>5</span>
                  <span>6</span>
                  <span>7</span>
                  <span>8</span>
                  <span>9</span>
                  <span>0</span>
                  ${Array(parseInt(res[i]) + 1)
                    .join(i)
                    .split(i)
                    .map( (x, j) => '<span>' + j + '</span>')
                    .join("")}
                </span>`
              );
            }
          }
        }

        ticks = [...stat.querySelectorAll("span[data-value]")];

        let activate = () => {
          let top = stat.getBoundingClientRect().top;
          let offset = window.innerHeight * 0.8;

          setTimeout(() => {
            fresh = false;
          }, time);

          if (top < offset) {
            setTimeout(() => {
              for (let tick of ticks) {
                let dist = parseInt(tick.getAttribute("data-value")) + 11;
                tick.style.transform = `translateY(-${dist * 100}%)`;
              }
            }, fresh ? time : 0);
            window.removeEventListener("scroll", activate);
          }
        };
        
        window.addEventListener("scroll", activate);
        activate();
      });
    },
    dateRangeFilter(rangeMonth, date) {
      let today = new Date(); // 2024-08
      let pastDate = new Date();
      pastDate.setMonth(today.getMonth() - rangeMonth); // 2023-08
            
      // 일자가 월에 유효한 일자인지 확인하여 보정
      while (pastDate.getMonth() === today.getMonth() - rangeMonth || 
             (today.getMonth() < rangeMonth && pastDate.getMonth() === 11 - (rangeMonth - today.getMonth()))) {
        pastDate.setDate(pastDate.getDate() - 1);
      }

      let is_List = pastDate < new Date(date); 
      return is_List;
    },
    weekKor(weekEng) {
      if(weekEng != undefined) {
        let week = weekEng.toLowerCase();
        let weekKor;
        switch(week) {
          case "mon" || 'monday' || 1 || '월':
            weekKor = "월요일"
            break;
          case "tue" || 'tuesday' || 2 || '화':
            weekKor = "화요일"
            break;
          case "wed" || 'wednesday' || 3 || '수':
            weekKor = "수요일"
            break;
          case "thu" || 'thursday' || 4 || '목':
            weekKor = "목요일"
            break;
          case "fri" || 'friday' || 5 || '금':
            weekKor = "금요일"
        }
        return weekKor;
      }
    },
    weekNum(weekString) {
      if(weekString != undefined) {
        let week = weekString.toLowerCase();
        let weekNum;
        switch(week) {
          case "월" || '월요일':
            weekNum = 1
            break;
          case "화" || '화요일':
            weekNum = 2
            break;
          case "수" || '수요일':
            weekNum = 3
            break;
          case "목" || '목요일':
            weekNum = 4
            break;
          case "금" || '금요일':
            weekNum = 5
        }
        return weekNum;
      }
    },
    // 날짜 생성 지역 이슈로 원래 날짜보다 -1 day 로 나옴.
    // plus 1 => 0, 2=>1 만큼 day가 추가됨.
    datePlus(date, plus) {
      let beforeDate = new Date(date);
      let returnDate = new Date( beforeDate.setDate(beforeDate.getDate() + plus) ).toISOString().split('T')[0].replaceAll('-', '. '); 
      returnDate = returnDate.substring(6)
      return returnDate;
    }
  }
})();

export default Utils;
